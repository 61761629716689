.fadeIn {
  animation-name: fadeIn; }

.fadeInUpSmooth {
  animation-name: fadeInUpSmooth;
  animation-timing-function: $easeSmooth; }

.fadeInUp {
  animation-name: fadeInUp;
  animation-timing-function: $easeSmooth; }

.fadeInLeftSmooth {
  animation-name: fadeInLeftSmooth;
  animation-timing-function: $easeSmooth; }

.bounceIn {
  animation-duration: 0.75s;
  animation-name: bounceIn; }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); }
  20% {
    transform: scale3d(1.1, 1.1, 1.1); }
  40% {
    transform: scale3d(0.9, 0.9, 0.9); }
  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03); }
  80% {
    transform: scale3d(0.97, 0.97, 0.97); }
  to {
    opacity: 1;
    transform: scale3d(1, 1, 1); } }

@keyframes fadeInUpSmooth {
  0% {
    transform: translate(0, 100%) scale(1, 2); }
  100% {
    transform: translate(0, 0) scale(1, 1); } }

@keyframes fadeInUp {
  0% {
    transform: translate(0, 100%);
    opacity: 0; }
  100% {
    transform: translate(0, 0);
    opacity: 1; } }

@keyframes fadeInLeftSmooth {
  0% {
    transform: translate(100%, 0) scale(2, 1);
    opacity: 0; }
  100% {
    transform: translate(0, 0) scale(1, 1);
    opacity: 1; } }



