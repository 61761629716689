body {
  background: $white;
  line-height: 1.4;
  font-size: $fz;
  min-width: 320px;
  font-family: 'gilroy', Arial, sans-serif;
  color: $c-text;
  @include no-scroll;
  @include sm {
    font-size: $fz-sm-px; } }

.icon {
  display: inline-block;
  fill: currentColor;
  font-size: inherit; }

.lazy {
  &.is-loaded:not(.no-fade-in) {
    animation: fadeIn 1s; } }

.out {
  &.has-fixed-header {
    padding-top: $header-height; }
  // &--full
  //   +flex(flex-start, normal, column)
  //   height: 100vh
  //   height: calc((var(--vh, 1vh) * 100)
  //   +md
  //     height: auto
  //     display: block
  //   .main
 }  //     flex-grow: 1

.main {
 }  // +tr(filter .5s)

.container {
  width: 100%;
  padding-left: 50px;
  padding-right: 50px;
  @include xl {
    padding-left: 30px;
    padding-right: 30px; }
  @include lg {
    padding-left: 15px;
    padding-right: 15px; }
  &--md {
    padding-left: 170px;
    padding-right: 170px;
    @include xl {
      padding-left: 150px;
      padding-right: 150px; }
    @include lg {
      padding-left: 90px;
      padding-right: 90px; }
    @include xs {
      padding-left: 15px;
      padding-right: 15px; } }
  &--sm {
    padding-left: 95px;
    padding-right: 95px;
    @include xl {
      padding-left: 30px + 45px;
      padding-right: 30px + 45px; } }
  &--xs {
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px !important;
    padding-right: 15px !important;
    max-width: 940px; } }

.logo {
  display: inline-block;
  font-size: 0;
  @include lg {
    width: 70px; } }

.close {
  @include icon-close(25, 2, currentColor);
  position: absolute;
  z-index: 1;
  top: 15px;
  right: 15px;
  @include tr(color .3s);
  @include hover {
    color: $accent; }
  &--sm {
    width: 15px;
    height: 15px; } }

.contact-man {
  text-align: center;
  &__text {
    margin-bottom: 15px;
    max-width: 620px;
    margin-left: auto;
    margin-right: auto; } }

.accent {
  color: $accent; }

.price {
  font-size: $fz-sm;
  font-weight: 700;
  color: $accent;
  small {
    font-weight: 300;
    color: $gray-100;
    font-size: $fz-sm-px; }
  &--lg {
    @include css-lock(24, 36, 320, 1399, font-size);
    small {
      font-size: $fz; } } }

.video-btn {
  @include flex(center, center);
  &__btn {
    width: 95px;
    height: 95px;
    border-radius: 50%;
    @include flex(center, center);
    border: 12px solid rgba($white, .2);
    margin-right: 20px;
    position: relative;
    transform: perspective(1px) translateZ(0);
    transition-duration: 0.5s;
    @include xs {
      flex: 0 0 95px; }
    @include hover {
      transform: scale(1.1);
      transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36); }
    @include before {
      @include vcenter;
      border-radius: 50%;
      background-color: $accent;
      z-index: 0;
      width: 100%;
      height: 100%; }
    @include after {
      @include arr(10, 15, $white, r);
      display: block;
      margin-left: .3em;
      z-index: 1; } } }

.fancy-title {
  position: relative;
  padding-top: 17%;
  font-weight: 700;
  margin-bottom: 20px;
  overflow: hidden;
  @include before {
    z-index: 1;
    left: 0;
    right: 0;
    height: 5px;
    background-color: $white;
    bottom: -2px;
    position: absolute; }
  svg, video {
    @include coverdiv;
    width: 100%;
    height: 100%; }
  svg {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 150%;
    height: 150%;
    z-index: 1;

    rect {
      fill: $white; }
    text {} }
  video {
    object-fit: cover;
    z-index: 0; }
  @include sm {
    padding-top: 0;
    font-size: em(50);
    font-weight: 700;
    text-align: center;
    @include image('about/maxresdefault-(2).jpg');
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 0%; }
  &__inner-mob {
    display: none;
    @include sm {
      display: block; } }
  &__inner-desc {
    @include sm {
      display: none; } }
  &__text {
    @include notfirst {
      margin-top: -.5em; } }
  &--production {
    svg {
      > rect {
        mask: url(#production-mask); } } }
  &--warranty {
    svg {
      > rect {
        mask: url(#warranty-mask); } } } }


.quote {
  position: relative;
  padding-top: em(22);
  &__title {
    margin-bottom: 10px;
    padding-bottom: 10px;
    position: relative;
    z-index: 1;
    @include before {
      width: 45px;
      border-top: 1px solid currentColor;
      bottom: 0;
      left: 0;
      position: absolute; } }
  &__text {
    font-style: italic;
    font-size: em(18);
    font-weight: 300;
    position: relative;
    z-index: 1;
    margin-bottom: 45px;
    @include lg {
      margin-bottom: 30px; }
    @include md {
      margin-bottom: 15px; } }
  &__subttl {
    font-size: 13px;
    color: $gray-100;
    font-weight: 300; }
  &__icon {
    opacity: .07;
    position: absolute;
    z-index: 0;
    top: 0;
    right: 20%;
    .icon {
      font-size: em(55); } }
  &__sign {
    text-align: right;
    font-size: 0; } }


