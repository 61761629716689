.tech-items {
  @include flex(flex-start, normal, row, wrap);
  margin-bottom: -60px;
  @include xl {
    margin-bottom: -50px; }
  @include lg {
    margin-bottom: -40px; }
  @include md {
    margin-bottom: -30px; }
  @include sm {
    margin-bottom: -20px; }
  &__item {
    width: 50%;
    padding-right: 15px;
    margin-bottom: 60px;
    @include xl {
      margin-bottom: 50px; }
    @include lg {
      margin-bottom: 40px; }
    @include md {
      margin-bottom: 30px;
      padding-right: 10px;
 }      // width: 100%
    @include sm {
      margin-bottom: 20px;
      width: 100%; }
    @include xss {
      padding-right: 0; } } }


.tech-item {
  @include flex;
  @include xss {
    display: block; }
  &__img {
    font-size: 0;
    @include xss {
      margin-bottom: 15px;
      img {
        width: 100%; } } }
  &__content {
    padding-left: 25px;
    max-width: 320px;
    @include md {
      padding-left: 10px; }
    @include xss {
      padding-left: 0;
      margin-left: auto;
      margin-right: auto; } }
  &__title {
    margin-bottom: 15px;
    @include md {
      margin-bottom: 10px; } }
  &__text {
    margin-bottom: 20px;
    @include md {
      margin-bottom: 15px; } }
  &__link {
    @include inline-flex(flex-start, center);
    color: #126eaf;
    font-weight: 300;
    .icon {
      font-size: em(30);
      margin-right: .65em; }
    span {
      text-decoration: underline; } } }
