.product-links {
  @include flex(flex-start, normal, row, wrap);
  margin-left: -25px;
  margin-right: -25px;
  margin-bottom: -25px;
  @include xl {
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: -15px; }
  &__item {
    padding-left: 25px;
    padding-right: 25px;
    width: 50%;
    margin-bottom: 25px;
    @include xl {
      padding-left: 15px;
      padding-right: 15px;
      margin-bottom: 15px; }
    @include sm {
      width: 100%; } } }


.product-link {
  display: block;
  @include cover;
  padding: 25px;
  padding-top: 25%;
  color: $white;
  @include lg {
    padding: 25% 20px 20px; }
  @include md {
    padding: 25% 15px 15px; }

  @include hover {
    .btn--white-hover {
      color: $accent;
      background-color: $white;
      border-color: $white; } }

  .btn--white-hover {
    @include hover {
      color: $white;
      border-color: $accent;
      @include before {
        transform: translate(0, 0); } } }

  &__content {
    display: block; }
  &__title {
    display: block;
    margin-bottom: 10px; }
  &__subttl {
    display: block;
    margin-bottom: 15px;
    font-size: em(18); }
  &__btn {
    display: block; } }
