.popup {
  $this: &;
  position: fixed;
  z-index: 15;
  overflow: auto;
  top: 0;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  right: 0;
  left: 0;
  perspective: 1000px;
  @include ready {
    @include tr(opacity .5s $easeCustom, visibility .5s $easeCustom); }
  @include hidden;
  &:not(.popup--full) {
    background-color: rgba($black, .9);
    padding: 15px;
    @include vertical; }
  &.is-active {
    @include visible;
    #{$this}__inner {
      transform: translate(0, 0) rotateX(0); }
    #{$this}__video {
      display: inline-block; } }
  &__inner {
    position: relative;
    background-color: $white;
    padding: 50px;
    width: 100%;
    max-width: 550px;
    display: inline-block;
    vertical-align: middle;
    font-size: $fz;
    transform: translate(0, 100%) rotateX(45deg);
    @include ready {
      @include tr(transform .5s $easeCustom); }
    @include xl {
      padding: 50px 30px 30px; }
    @include lg {
      padding: 50px 20px 20px; }
    @include md {
      padding: 50px 15px 15px; }
    @include sm {
      font-size: $fz-sm-px; } }
  &__item-card {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
    font-size: $fz;
    text-align: left;
    @include sm {
      font-size: $fz-sm-px; } }
  &__close {
    color: $c-text; }
  &__video {
    display: none;
    font-size: 0;
    video {
      width: 100%;
      object-fit: cover;
      max-height: calc(100vh - 100px);
      max-height: calc((var(--vh, 1vh) * 100) - 100px);
      @include xl {
        max-height: calc(100vh - 80px);
        max-height: calc((var(--vh, 1vh) * 100) - 80px); }
      @include lg {
        max-height: calc(100vh - 70px);
        max-height: calc((var(--vh, 1vh) * 100) - 70px); }
      @include md {
        max-height: calc(100vh - 65px);
        max-height: calc((var(--vh, 1vh) * 100) - 65px); } } }
  &__left, &__right {
    width: 50%;
    @include flex(center, center, column); }

  &__right {
    border-left: 1px solid #3b3b3b;
    #{$this}__link-img {
      img {
        width: 130%;
        max-width: none;
        max-height: none;
        transform: translate(0, 0);
        right: 0;
        left: auto; } }
    #{$this}__link-title {
      max-width: 80%;
      margin-left: auto; } }

  &__link {
    display: block;
    position: relative;
    width: 180px;
    @include md {
      width: 120px; }
    @include xs {
      width: 100px; }
    @include xss {
      width: 80px; }
    @include hover {
      #{$this}__link-title {
        border-bottom-color: $accent;
        @include before {
          @include visible; } } } }

  &__link-title {
    display: block;
    border-bottom: 1px solid transparent;
    @include tr(border-color .3s);
    position: relative;
    padding: .5em 0;
    padding-right: 10px;
    padding-left: 10px;
    width: 100%;
    @include before {
      @include icon-arrow(8, 8, 1, $accent, 225);
      right: 0;
      top: 50%;
      transform: translate(0, -50%) rotate(225deg);
      position: absolute;
      @include hidden;
      @include tr(opacity, visibility .3s); } }
  &__link-img {
    position: relative;
    padding-top: 130%;
    display: block;
    margin-bottom: 30px;
    @include md {
      margin-bottom: 15px; }
    @include xs {
 }      // padding-top: 150%
    img {
     position: absolute;
     max-height: 100%;
     top: 0;
     left: 50%;
     transform: translate(-50%, 0); } }

  &--full {
    #{$this}__inner {
      // background-color: rgba($white, .9)
      height: 100%;
      max-width: none;
      @include vertical;
      overflow: auto; }
    #{$this}__video {
      vertical-align: middle; } }
  &--dark {
    color: $white;
    #{$this}__inner {
      background-color: rgba($black-light, .95); }
    #{$this}__close {
      color: $white; } }
  &--half {
    #{$this}__inner {
      font-size: $fz;
      padding-top: 0;
      padding-bottom: 0;
      @include flex;
      @include sm {
        font-size: $fz-sm-px; } } } }

