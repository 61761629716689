.nav {

  li {
    display: inline-block;
    @include notfirst {
      margin-left: 35px;
      @include md {
        margin-left: 0; } }
    @include md {
      display: block;
      padding-top: 10px;
      padding-bottom: 10px; } }


  a, button {
    text-transform: uppercase;
    @include tr(color .3s);
    &.is-active {
      color: $white-dark; }
    @include hover {
      color: $white-dark; }
    .header.is-white & {
      &.is-active {
        color: $gray; }
      @include hover {
        color: $gray; } } }
  &--content {
    li {
      @include md {
        display: inline-block;
        margin-left: 15px;
        margin-right: 15px; } } }
  &--footer {
    li {
      display: block;
      @include notfirst {
        margin-left: 0; }
      @include notlast {
        margin-bottom: 10px; }
      @include md {
        padding-top: 0;
        padding-bottom: 0; } } } }
