.stages {
  max-width: 905px;
  margin-left: auto;
  margin-right: auto; }


.stage {
  $this: &;
  @include cover;
  background-color: $gray-light;
  position: relative;
  color: $white;
  padding: 10% 15px 5% 10%;
  cursor: pointer;
  @include tr(padding .3s);
  @include notlast {
    margin-bottom: 10px; }
  &:hover {
    padding-top: 5%;
    #{$this}__title {
      @include hidden;
      max-height: 0;
      overflow: hidden; }
    #{$this}__nmb {
      transform: translate(5%, -50%); } }

  &__nmb {
    font-weight: 700;
    color: #5b5b5b;
    @include css-lock(60, 150, 320, 1399, font-size);
    position: absolute;
    z-index: 0;
    top: 50%;
    transform: translate(50%, -50%);
    left: 0;
    opacity: .15;
    @include tr(transform .3s); }
  &__title {
    position: relative;
    z-index: 1;
    // max-height: 3em

    @include tr(max-height .3s); }
  &__descr {
    max-width: 450px;
    max-height: 0;
    overflow: hidden;
    @include tr(max-height .3s); } }
