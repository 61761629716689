small {
  font-size: inherit; }

// h1, h2, h3, h4, h5, h6
//   font-weight: 400

.title {
  @include css-lock(24, 36, 320, 1399, font-size);
  line-height: 1.1;
  display: block;
  font-weight: 700;
  small {
    font-weight: 300;
    font-size: em(30, 36);
    display: block; }
  &--h3 {
    @include css-lock(18, 24, 320, 1399, font-size); }
  &--h4 {
    @include css-lock(16, 18, 320, 1399, font-size); }
  &--h5 {
    font-size: 1em !important; }
  &--light {
    font-weight: 300; }
  &--reg {
    font-weight: 400; }
  &--semi {
    font-weight: 600; }
  &--ttu {
    text-transform: uppercase; }
  &--lg {
    @include css-lock(36, 80, 320, 1399, font-size);
    line-height: 1;
    small {
      font-size: 1em; } }
  &--md {
    @include css-lock(30, 72, 320, 1399, font-size);
    line-height: 1;
    small {
      font-size: 1em; } }
  &--md-s {
    @include css-lock(28, 48, 320, 1399, font-size);
    line-height: 1;
    small {
      font-size: 1em; } }
  &--inl {
    small {
      display: inline; } } }

.text {
  &--light {
    font-weight: 300; }
  &--gray-100 {
    color: $gray-100; }
  &--white-dark {
    color: $white-dark; }
  &--md {
    font-size: em(18);
    @include xs {
      font-size: 1em; } } }
