.overflow-hidden {
  overflow: hidden; }

.pb-0 {
  padding-bottom: 0 !important; }
.pt-0 {
  padding-top: 0 !important; }

.text-left {
  text-align: left; }

.text-center {
  text-align: center; }

.accent {
  color: $accent !important; }
