.comfort {
  padding-top: 130px;
  padding-bottom: 300px;
  position: relative;
  max-width: 1195px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  @include lg {
    padding-top: 100px;
    padding-bottom: 210px; }
  @include sm {
    padding-top: 70px; }
  @include xs {
    padding-top: 40px;
    padding-bottom: 100px; }
  &__img {
    @include coverdiv;
    z-index: 0;
    img {
      @include vcenter;
      max-height: 100%;
      @include lg {
        transform: translate(0, -50%);
        left: 0;
        max-width: 470px; }
      @include sm {
        max-width: 60%; }
      @include xss {
        max-width: 190px; } } }
  &__content {
    margin-left: auto;
    max-width: 290px;
    position: relative;
    z-index: 1;
    @include lg {
      margin-left: 470px + 15px; }
    @include sm {
      margin-left: 330px; }
    @include xs {
      margin-left: 200px; } }
  &__text {
    position: relative;
    @include sm {
      font-size: 11px; }
    @include notlast {
      margin-bottom: 60px;
      @include lg {
        margin-bottom: 40px; }
      @include sm {
        margin-bottom: 0; } }
    &:nth-child(1) {}

    &:nth-child(2) {
      @include sm {
        top: 30px; }
      @include xs {
        top: 10px; } }
    &:nth-child(3) {
      top: 60px;
      @include lg {
        top: 30px; }
      @include sm {
        top: 90px; }
      @include xs {
        top: 30px; } } } }
