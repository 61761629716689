.item-pres {
  &__top {
    @include flex(space-between, center);
    margin-bottom: 30px;
    @include md {
      margin-bottom: 15px; } }
  &__text {
    margin-bottom: 30px;
    @include md {
      margin-bottom: 15px; } }
  &__btns {
    @include flex(flex-start, center);
    .btn {
      &:first-child {
        margin-right: 10px; } } } }

.item-pres-table {
  padding: 15px 25px 25px;
  background-color: $gray-lightest;
  @include sm {
    padding: 10px; }
  table {
    font-weight: 300;
    button:not(.btn) {
      text-decoration: underline;
      @include inline-flex(flex-start, center);
      @include tr(color .3s);
      @include hover {
        color: $accent; }
      .icon {
        font-size: 1.2em;
        margin-right: .3em; } }
    tr {
      &:nth-child(2) {
        td {
          padding-top: 15px; } } }
    td, th {
      padding: 4px 0;
      // border: 1px solid #e2e2e2
      vertical-align: middle;
      @include notfirst {
        text-align: center; }
      // +sm
 }      //   padding-: 10px
    th {
      text-align: left;
      font-weight: 700;
      padding: 15px 0;
      border-bottom: 1px solid #e2e2e2;
      small {
        font-weight: 300; } }

    .btn--comb {
      margin-left: .5em;
      @include xs {
        display: none; } }
    .js-lightgallery-trigger {
      @include xs {
        .icon {
          display: none; } } } } }

.item-pres-block {
  &__inner {
    @include flex(flex-start, normal, row, wrap); }
  &__left {
    width: percentage(510/1440);
    .slider__wrap, .swiper-container {
      height: 100%; }
    @include lg {
      width: 40%; }
    @include md {
      width: 100%; } }
  &__right {
    width: percentage((1440 - 510)/1440);
    @include flex(center, normal, column);
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5%;
    padding-bottom: 5%;
    @include lg {
      width: 60%; }
    @include md {
      width: 100%; } }
  &__content {
    max-width: 665px;
    margin-left: auto;
    margin-right: auto;
    @include md {
      max-width: none; } } }
