.btn {
  $this: &;
  @include inline-flex(center, center);
  color: $white;
  background-color: $accent;
  border: 1px solid  $accent;
  text-align: center;
  text-transform: uppercase;
  min-width: 180px;
  padding: .7em 1em .5em;
  position: relative;
  overflow: hidden;
  @include tr(background-color .3s, color .5s, border-color .5s);
  @include before {
    @include coverdiv;
    z-index: 0;
    background-color: $white;
    transform: translate(100%, 0);
    @include tr(transform .5s $easeCustom); }
  @include hover {
    &:not(#{$this}--no-hover) {
      // background-color: $white
      color: $accent;
      @include before {
        transform: translate(0, 0); } } }
  @include xs {
    min-width: 0;
    width: 100%; }
  > span {
    position: relative;
    z-index: 1; }
  .icon {
    font-size: 1.3em;
    margin-left: .4em; }

  &--transp-gray {
    color: #474747;
    background-color: transparent;
    border-color: $gray-light;
    @include hover {
      background-color: transparent;
      color: $accent;
      border-color: $accent; } }
  &--full {
    min-width: 0;
    width: 100%; }
  &--comb {
    height: 39px;
    min-width: 39px;
    border: 2px solid currentColor;
    color: $accent;
    position: relative;
    border-radius: 25px;
    background-color: $white;
    padding: .6em .5em .5em;
    // padding: .6em .2em .5em
    font-weight: 700;
    text-transform: none;
    overflow: hidden;
    @include before {
      display: none; }
    @include xs {
      min-width: 39px; }
    @include hover {
      &:not(#{$this}--no-hover) {
        color: $white;
        span {
          max-height: none;
          max-width: 300px;
          margin-left: 10px; } } }
    span {
      position: relative;
      max-width: 0;
      max-height: 0;
      overflow: hidden;
      display: block;
      top: -.1em;
      @include tr(transform .3s, max-width .3s, margin .3s); }
    .icon {
      font-size: 1.2em;
      margin-left: 0; }
    @include hover {
      background-color: $accent;
      border-color: $accent;
      color: $white; } }
  &--comb-sm {
    height: 17px;
    width: 17px;
    min-width: 0;
    border-width: 1px;
    padding: 0;
    font-size: .6em;
    @include hover {
      &:not(#{$this}--no-hover) {
        span {
          max-height: 0;
          max-width: 0;
          margin-left: 0; } } } }
  &--white-hover {
    @include before {
      background-color: $accent; } } }
