.prev, .next {
  @include flex(center, center);
  background-image: none;
  background-color: rgba($white, .5);
  opacity: .2;
  box-shadow: 1px 1px 8px rgba(51,51,51,.3);
  width: 45px;
  height: 45px;
  padding: 5px 0;
  @include tr(transform .3s, opacity .3s);
  @include before {
    @include icon-arrow(20, 20, 1, $c-text, 45);
    display: block;
    margin-left: .6em; }
  @include hover {
    transform: translate(-5px, 0);
    opacity: 1; }
  @include lg {
    width: 30px;
    height: 30px;
    @include before {
      width: 14px;
      height: 14px; } } }

.next {
  transform: scaleX(-1);
  @include hover {
    transform: translate(5px, 0) scaleX(-1); } }

.prev-vert, .next-vert {
  @include inline-flex(center, center);
  background-color: $gray-lightest;
  width: 25px;
  height: 25px;
  @include tr(background-color .3s);
  @include hover {
    background-color: #e6e6e6; }
  @include before {
    @include icon-arrow(8, 8, 1, currentColor, 135);
    display: block;
    margin-top: .3em; } }

.next-vert {
  transform: scaleY(-1); }

.prev-hor, .next-hor {
  @extend .prev-vert;
  @include before {
    @include rotate(45); } }

.next-hor {
  transform: scaleX(-1); }

.slider {
  &__wrap {
    position: relative;
    overflow: hidden;
    * {
      box-sizing: border-box; } }
  &__controls {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 1; }
  &__prev {
    position: absolute;
    left: 0; }
  &__next {
    position: absolute;
    right: 0; }
  &__pagination {
    @extend .container;
    position: absolute;
    z-index: 1;
    bottom: 50px;
    left: 0;
    width: 100%;
    text-align: right;
    @include xs {
      display: none; }
    .swiper-pagination-bullet {
      cursor: pointer;
      width: 55px;
      height: 25px;
      color: $white;
      margin-right: 7px;
      margin-left: 7px;
      border-radius: 0;
      background-color: transparent;
      opacity: 1;
      display: inline-block;
      overflow: hidden;
      position: relative;
      @include after {
        position: absolute;
        bottom: 0;
        border-bottom: 5px solid $accent;
        left: 0;
        right: 0;
        z-index: 1;
        transform: translate(-100%, 0); }
      @include before {
        position: absolute;
        bottom: 0;
        border-bottom: 5px solid $white;
        left: 0;
        right: 0;
        z-index: 0; }
      &.swiper-pagination-bullet-active {
        @include after {
          transform: translate(0, 0); } } }
    &:not(.no-autoplay) {
      .swiper-pagination-bullet {
        &.swiper-pagination-bullet-active {
          @include after {
            animation: progressBar 5s forwards linear; } } } } }

  &--hero {
    &__wrap {
      .slider {
        &__pagination {
          @extend .container--md; } } } }

  &--thumbs {
    &__wrap {
      @include flex(flex-start, normal, column);
      // height: 100%
      position: absolute;
      left: 0;
      right: 30px;
      bottom: 0;
      top: 0;
      @include lg {
        right: 15px; }
      @include sm {
        right: 5px; }
      .swiper-container {
        flex-grow: 1;
        margin-bottom: 4px;
        width: 100%; }
      .slider {
        &__controls {
          width: 100%;
          position: static;
          transform: translate(0, 0);
          @include flex; }
        &__next, &__prev {
          position: static;
          width: calc(50% - 2px); }
        &__prev {
          margin-right: 2px; }
        &__next {
          margin-left: 2px; } } } }

  &--products {
    &__wrap {
      @include flex;
      // padding-left: 35px
      // padding-right: 35px
      .swiper-container {
        flex-grow: 1; }
      .slider {
        &__prev, &__next {
          height: auto;
          width: 25px;
          flex: 0 0 25px;
          top: 0;
          bottom: 0;
          z-index: 2;
          position: static;
          &.swiper-button-disabled {
            display: none; } }
        &__next {
          right: 0;
          margin-left: 10px; }
        &__prev {
          left: 0;
          margin-right: 10px; } } } }

  &--half {
    &__wrap {
      .slider {
        &__pagination {
          bottom: auto;
          top: 40px;
          padding-left: 0;
          padding-right: 0;
          width: 50%;
          right: 0;
          left: auto;
          text-align: left;
          padding-left: 35px;
          @include lg {
            top: 20px; }
          @include sm {
            padding-left: 15px;
            padding-right: 15px;
            width: 100%;
            text-align: right; } } } } }
  &--full {
    &__wrap {
      .slide {
        @include cover;
        padding-top: 50%;
        @include md {
          padding-top: 90%; } }
      .slider {
        &__pagination {
          bottom: auto;
          top: 40px;
          width: 100%;
          text-align: left;
          @include lg {
            top: 20px; } } } } }


  // &--one-third
  //   &__wrap
  //     .slider
  //       &__pagination
  //         bottom: auto
  //         top: 40px
  //         width: percentage(510/1440)
  //         text-align: left
  //         padding-left: 50px
  //         +lg
  //           top: 20px
  //         +sm
  //           padding-left: 15px
  //           padding-right: 15px
  //           width: 100%
 }  //           text-align: right


@keyframes progressBar {
  0% {
    transform: translate(-100%, 0); }
  100% {
    transform: translate(0, 0); } }

