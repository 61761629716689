.products {
  @include clr;
  margin-left: -3px;
  margin-right: -3px;

  &__item {
    float: left;
    width: 25%;
    padding-left: 3px;
    padding-right: 3px;
    margin-bottom: 6px;
    font-size: 0;
    @include lg {
      width: percentage(1/3); }
    @include sm {
      width: 50%; }
    @include xs {
      width: 100%; } }
  &__item-lg {
    width: 50%;
    @include lg {
      width: percentage(2/3); }
    @include sm {
      width: 100%; } } }


.product {
  $this: &;
  $height: 550px;
  $height-md: 500px;
  $height-xs: 300px;
  @include flex(flex-start, normal, column);
  width: 100%;
  background-color: $gray-lightest;
  position: relative;
  font-size: $fz;
  height: $height;
  @include md {
    height: $height-md; }
  @include sm {
    font-size: $fz-sm-px; }
  @include xs {
    height: $height-xs; }
  @include before {
    border-top: 2px solid  $accent;
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    bottom: 0;
    @include hidden;
    @include tr(opacity .3s, visibility .3s); }
  &:hover {
    @include before {
      @include visible; }
    #{$this}__title {
      color: $white; }
    #{$this}__img {
      img {
        @include hidden; } }
    #{$this}__img-img {
      @include visible; }
    #{$this}__descr {
      display: block; } }
  &__descr {
    display: none;
    color: $white;
    position: absolute;
    top: 50%;
    left: 30px;
    right: 30px;
    transform: translate(0, -50%);
    z-index: 1; }
  &__title-block {
    display: block;
    position: absolute;
    z-index: 1;
    top: 30px;
    left: 30px;
    right: 30px; }
  &__img {
    display: block;
    height: 100%;
    width: 100%;
    img {
      max-height: 70%;
      min-width: 30px;
      min-height: 30px;
      @include tr(opacity .3s, visibility .3s);
      &.is-loaded {
        @include vcenter; } } }
  &__img-img {
    display: block;
    @include cover;
    @include coverdiv;
    z-index: 0;
    @include tr(opacity .3s, visibility .3s);
    @include hidden;
    @include before {
      @include coverdiv;
      background-color: rgba($black, .5); } }
  &__bottom {
    display: block;
    width: 100%;
    background-color: $gray-light;
    text-align: center;
    padding-top: 8px;
    padding-bottom: 8px; }
  &__title {
    display: block;
    text-transform: uppercase;
    font-weight: 600;
    @include tr(color .3s); }
  &__title-lg {
    margin-bottom: 10px;
    display: block; }
  &__price {
    color: $gray-100;
    .accent {
      font-size: em(18); } }
  &__content {
    color: $white;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 1;
    left: 0;
    padding-left: 45px;
    padding-right: 45px;
    @include lg {
      padding-left: 30px;
      padding-right: 30px; }
    @include md {
      padding-left: 15px;
      padding-right: 15px; } }
  &__link {
    display: block;
    flex-grow: 1; }
  &__text {
    display: block;
    max-width: 500px;
    @include notlast {
      margin-bottom: 15px; } }
  &__btn {
    display: block; }


  &--sm {
    height: ($height - 6px) / 2;
    @include md {
      height: ($height-md - 6px) / 2; } }
  &--lg {
    @include hover {
      @include before {
        @include hidden; } }
    #{$this}__img-img {
     @include visible; }
    @include xs {
      height: $height-xs * 2; } } }

