$fz:       15px;
$fz-sm-px: 14px;
$fz-xs-px: 12px;
$fz-sm:    em(14);
$fz-xs:    em(12);

$c-text: #000;

$accent: #f46f16;
$black: #000;
$black-light: #202020;
$white: #fff;
$white-dark: #c9c9c9;

$gray: #767676;
$gray-light: #bababa;
$gray-lightest: #f9f9f9;
$gray-100: #848484;

$header-height: 55px;
$header-height-md: 40px;

$header-transp-height: 105px;
$header-transp-height-lg: 55px;

$footer-height: 91px;
$footer-height-sm: 128px;
