.item-descr {
  $this: &;
  margin-left: auto;
  margin-right: auto;
  max-width: 825px;
  @include flex(space-between);
  position: relative;
  padding-top: 5%;
  padding-bottom: 40px;
  // overflow: hidden
  @include sm {
    display: block; }
  &__img {
    @include coverdiv;
    z-index: 0;
    text-align: center;
    img {
      max-height: 100%; }
    @include sm {
      display: none; } }
  &__cards {
    @include flex(center, normal, column);
    width: 180px;
    position: relative;
    z-index: 1;
    @include sm {
      flex-direction: row;
      flex-wrap: wrap;
      width: auto;
      margin-left: -2px;
      margin-right: -2px; } }
  &__cards-left {
    #{$this}__card {
      &:nth-child(1) {
        #{$this}__modal {
          right: auto;
          left: 0;
          top: 50px;
          bottom: auto; } }
      &:nth-child(2) {
        #{$this}__modal {
          right: auto;
          left: 0;
          top: 50px;
          bottom: auto; } }
      &:nth-child(3) {
        #{$this}__modal {
          right: auto;
          left: 0;
          top: auto;
          bottom: 50px; } } } }
  &__cards-right {
    #{$this}__card {
      &:nth-child(1) {
        #{$this}__modal {
          top: 50px;
          bottom: auto; } }
      &:nth-child(2) {
        #{$this}__modal {
          top: 50px;
          bottom: auto;
          // right: auto
          // left: 0
          // top: 50px
 } } } }          // bottom: auto
  &__card {
    height: 180px;
    position: relative;
    @include notlast {
      margin-bottom: 30px;
      @include md {
        margin-bottom: 15px; } }
    @include sm {
      width: 180px - 4px;
      margin-right: 2px;
      margin-left: 2px;
      margin-bottom: 15px;
      position: static; }
    @include xs {
      width: calc(50% - 4px);
      height: auto; } }
  &__modal {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 3;
    width: 575px;
    @include sm {
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%);
      right: auto !important;
      bottom: auto !important;
      width: 100%; } } }

.item-descr-modal {
  background-color: $gray-lightest;
  border-bottom: 2px solid  $accent;
  @include hidden;
  @include ready {
    @include tr(opacity .3s, visibility .3s); }
  &.is-active {
    @include visible; }
  &__inner {
    padding: 50px 30px; }
  &__title, &__text {
    margin-bottom: 15px;
    @include md {
      margin-bottom: 10px; } } }

