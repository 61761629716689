.footer {
  background-color: #3e4043;
  color: #969696;
  &__inner {
    @include flex(flex-start, normal, row, wrap);
    padding-top: 45px;
    padding-bottom: 45px;
    @include lg {
      padding-top: 30px;
      padding-bottom: 30px; } }
  &__left, &__right {
    width: percentage(335/1340);
    @include md {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      text-align: center; } }
  &__right {
    text-align: center;
    padding-left: 15px; }
  &__left {
    padding-right: 15px;
    @include md {
      margin-bottom: 15px; } }
  &__middle {
    width: calc(#{percentage((1340 - 335 - 335) / 1340)} - 30px);
    @include flex(space-around, normal, row, wrap);
    margin-left: -15px;
    margin-right: -15px;
    @include md {
      width: calc(100% + 30px); }
    @include xs {
      justify-content: center;
      text-align: center; } }
  &__col {
    padding-left: 15px;
    padding-right: 15px;
    @include md {
      margin-bottom: 15px; }
    @include xs {
      width: 100%; } }
  &__text {
    color: #6e6e6e;
    font-size: em(13);
    margin-bottom: 15px;
    @include md {
      margin-bottom: 10px; } }
  &__logo {
    margin-bottom: 10px; }
  &__copy {
    text-transform: uppercase;
    @include md {
      display: none; } }
  &__copy-mob {
    display: none;
    @include md {
      display: block; } }
  &__title {
    color: $white;
    margin-bottom: 15px;
    @include md {
      margin-bottom: 10px; } }
  &__social {
    margin-bottom: 15px;
    @include md {
      margin-bottom: 10px; } }
  &__contacts {
    @include md {
      margin-bottom: 15px; } } }

.footer-contact {
  @include flex(center, center);
  @include notlast {
    margin-bottom: 10px; }
  .icon {
    font-size: 1.2em;
    margin-right: .5em; }
  a {
    @include tr(color .3s);
    @include hover {
      color: $accent; } } }
