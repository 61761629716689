.section {
  padding-top: 65px;
  padding-bottom: 65px;
  position: relative;
  @include xl {
    padding-top: 50px;
    padding-bottom: 50px; }
  @include lg {
    padding-top: 40px;
    padding-bottom: 40px; }
  @include md {
    padding-top: 30px;
    padding-bottom: 30px; }
  @include sm {
    padding-top: 20px;
    padding-bottom: 20px; }
  &__inner {
    position: relative;
    z-index: 1; }
  &__bg {
    @include coverdiv;
    z-index: 0;
    @include before {
      @include coverdiv;
      z-index: 1;
      background-color: rgba($black, .5); }
    video {
      @include coverdiv;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 0; } }
  &__title {
    text-align: center;
    margin-bottom: 65px;
    @include xl {
      margin-bottom: 50px; }
    @include lg {
      margin-bottom: 40px; }
    @include md {
      margin-bottom: 30px; }
    @include sm {
      margin-bottom: 20px; }
    .title {
      margin-bottom: 30px;
      @include sm {
        margin-bottom: 20px; } } }
  &__img {
    margin-bottom: 30px;
    font-size: 0;
    img {
      display: inline-block; } }
  &__subttl {
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 75px;
    text-align: center;
    @include xl {
      margin-bottom: 50px; }
    @include lg {
      margin-bottom: 40px; }
    @include md {
      margin-bottom: 30px; }
    @include sm {
      margin-bottom: 20px; } }

  &--gray-lightest {
    background-color: $gray-lightest; }
  &--sm {
    padding-top: 25px !important;
    padding-bottom: 25px !important; }
  &--lg {
    padding-top: 155px;
    @include xl {
      padding-top: 80px; } }
  &--full {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    // padding: 0
    // height: 100%
    // +xl
    //   padding: 0
    // +lg
    //   padding: 0
    // +md
    //   height: auto
    //   padding-top: 20%
    //   padding-bottom: 20%
    // +sm
    //   padding-top: 30%
 }    //   padding-bottom: 30%
  &--full-children {
    padding: 0 !important;
    @include md {
      height: 100%; } }
  &--white {
    color: $white; }
  &--center {
    text-align: center;
    @include flex(center, center); }
  &--bg {
    @include cover; }
  &--overlay {
    position: relative;
    .container {
      z-index: 1;
      position: relative; }
    @include before {
      @include coverdiv;
      z-index: 0;
      background-image: linear-gradient(to right, rgb(0,0,0) 0%, rgba(0,0,0,0) 100%); } }
  &--black {
    background-color: $black;
    color: $white; }
  &--bdb-mob {
    @include sm {
      border-bottom: 1px solid  $gray-light; } } }


.section-block {
  $this: &;
  &__title {
    margin-bottom: 15px;
    @include sm {
      margin-bottom: 10px; } }
  &__subttl {
    font-size: em(18);
    margin-bottom: 25px;
    @include md {
      margin-bottom: 15px; } }
  &--md {
    #{$this}__title {
      margin-bottom: 25px;
      @include md {
        margin-bottom: 15px; } }
    #{$this}__text {
      max-width: 430px; } } }



.section-video {
  color: $white;
  padding-bottom: 65px;
  padding-left: 50px;
  @include xl {
    padding-bottom: 50px; }
  @include lg {
    padding-bottom: 40px;
    padding-left: 20px; }
  @include md {
    padding-bottom: 30px;
    padding-left: 0; }
  @include sm {
    padding-bottom: 20px; }
  &__text {
    width: 50%;
    padding-right: 15px;
    // +flex(flex-start, center, column)
    @include sm {
      width: 100%;
      margin-bottom: 15px;
      text-align: center; }
    p {
      width: 100%;
      max-width: 425px;
      @include sm {
        margin-left: auto;
        margin-right: auto; }
      @include notlast {
        margin-bottom: 25px;
        @include md {
          margin-bottom: 15px; } } } }
  &__title {
    margin-bottom: 25px;
    padding-right: 15px;
    max-width: 50%;
    // +flex(center, normal)
    @include sm {
      max-width: none;
      text-align: center; }
    @include md {
      margin-bottom: 15px; } }
  &__content {
    @include flex(flex-start, normal, row, wrap); }

  &__btn {
    width: 50%;
    @include flex(center, normal, column);
    @include sm {
      width: 100%; } } }

.s-frames {
  .no-touch & {
    height: 300vh;
    @include sm {
      height: auto; } }
  &__inner {
    .no-touch & {
      position: sticky;
      top: $header-height;
      @include flex(center, normal, column);
      @include sm {
        position: relative;
        top: auto;
        display: block; } } } }
