.breadcrumbs {
  li {
    display: inline-block; }
  .is-active {
    pointer-events: none;
    color: $white-dark; }
  a {
    @include tr(color .3s);
    @include hover {
      color: $white-dark; } } }
