.text-block {
  $this: &;
  @include flex(flex-start, normal, row, wrap);
  @include notlast {
    margin-bottom: 30px;
    @include md {
      margin-bottom: 15px; } }
  &__left, &__right {
    width: 50%;

    @include sm {
      width: 100%; } }
  &__left {
    font-size: 0;
    img {
      width: 100%; } }
  &__img-wrap {
    text-align: right;
    @include sm {
      text-align: center; }
    img {
      width: auto; } }
  &__right {
    @include flex(center, normal, column); }
  &__content {
    padding-left: 45px;
    padding-right: 45px;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: justify;
    max-width: 620px;
    @include lg {
      padding-left: 15px;
      padding-right: 15px; }
    @include sm {
      padding-left: 0;
      padding-right: 0;
      padding-top: 15px;
      padding-bottom: 15px;
      max-width: none; }
    p {
      @include notlast {
        margin-bottom: 30px;
        @include md {
          margin-bottom: 15px; } } } }
  &__title {
    margin-bottom: 30px;
    @include md {
      margin-bottom: 15px; } }
  &__img {
    @include cover;
    padding-top: 50%;
    height: 100%; }

  &--reverse {
    #{$this}__left {
      order: 1;
      @include sm {
        order: 0; } }
    #{$this}__right {
      order: 0;
      @include sm {
        order: 1; } } }
  &--lg {
    #{$this}__content {
      padding-left: 90px;
      max-width: 650px;
      @include xl {
        padding-left: 45px; }
      @include lg {
        padding-left: 15px; }
      @include sm {
        padding-left: 0; } }
    #{$this}__title {
      margin-bottom: 40px;
      @include lg {
        margin-bottom: 30px; }
      @include md {
        margin-bottom: 15px; } } } }
