.terms {
  @include flex(flex-start, normal, row, wrap);
  margin-left: -35px;
  margin-right: -35px;
  @include xl {
    margin-left: -25px;
    margin-right: -25px; }
  @include lg {
    margin-left: -15px;
    margin-right: -15px; }

  &__block {
    padding-left: 35px;
    padding-right: 35px;
    width: 50%;
    @include xl {
      padding-left: 25px;
      padding-right: 25px; }
    @include lg {
      padding-left: 15px;
      padding-right: 15px; }
    @include sm {
      width: 100%;
      @include notlast {
        margin-bottom: 15px; } } }
  &__title {
    margin-bottom: 45px;
    @include lg {
      margin-bottom: 30px; }
    @include md {
      margin-bottom: 15px; } }
  &__item {
    border-bottom: 1px solid  $gray-100;
    padding-bottom: 10px;
    @include notlast {
      margin-bottom: 30px; } }
  &__content {
    max-width: 520px;
    @include sm {
      max-width: none; } } }
