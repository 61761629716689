.contacts {
  height: 100%;
  @include flex(flex-start, normal, row, wrap);

  &__left, &__right {
    width: 50%;
    @include md {
      width: 100%; } }
  &__right {
    background-color: $black-light;
    @include flex(center, center);
    padding: 15px;
    @include md {
      display: block; } }
  &__img {
    height: 100%;
    @include cover;
    background-color: $gray;
    @include md {
      padding-top: 50%; }
    @include xs {
      padding-top: 70%; } } }


// .map
//   position: relative
//   background-color: $gray
//   height: 100%
//   +md
//     padding-top: 50%
//   +xs
//     padding-top: 70%
//   iframe
//     position: absolute
//     top: 0
//     left: 0
//     width: 100%
//     height: 100%

.contacts-block {
  color: $white;
  width: 100%;
  max-width: 625px;
  &__title {
    margin-bottom: 30px;
    @include md {
      margin-bottom: 15px; } }
  &__contacts {
    margin-bottom: 35px;
    @include flex(flex-start, normal, row, wrap);
    @include md {
      margin-bottom: 20px; }
    .contacts-meta {
      width: 50%;
      padding-right: 15px;
      margin-bottom: 45px;
      @include xl {
        margin-bottom: 30px; }
      @include md {
        margin-bottom: 15px; } } } }

.contacts-meta {
  &__title {
    font-weight: 300;
    text-transform: uppercase;
    color: $gray-100;
    margin-bottom: .5em; }
  &__text {
    font-size: em(18);
    a {
      @include tr(color .3s);
      @include hover {
        color: $accent; } } } }
