.about-descr {
  position: relative;
  @include flex(space-between);
  // padding-top: 10%
  // padding-bottom: 10%
  height: calc(100vh - #{$header-height});
  height: calc((var(--vh, 1vh) * 100) - #{$header-height});
  @include sm {
    display: block;
    height: auto;
    text-align: center; }
  &__left, &__right {
    width: percentage(480/1340);
    z-index: 1;
    position: relative;
    @include flex(center, normal, column);
    .no-touch & {
      opacity: 0;
      @include sm {
        opacity: 1; } }
    @include lg {
      width: 43%; }
    @include sm {
      width: 100%; } }
  &__left {
    padding-right: 15px;
    @include sm {
      margin-bottom: 15px;
      padding-right: 0; } }
  &__right {
    padding-left: 30px;
    @include lg {
      padding-left: 15px; }
    @include sm {
      padding-left: 0; } }
  &__img {
    @include coverdiv;
    z-index: 0;
    @include sm {
      display: none; }
    img {
      @include vcenter;
      max-height: 100%;
      .no-touch & {
        display: none; } }
    canvas {
      display: block;
      @include coverdiv;
      width: 100%;
      height: 100%; } }
  &__title {
    margin-bottom: 25px;
    @include md {
      margin-bottom: 15px; } }
  &__text {
    max-width: 440px;
    @include sm {
      margin-left: auto;
      margin-right: auto; } }
  &__features {
    .feature {
      .no-touch & {
        // opacity: 0
        @include sm {
          opacity: 1; } } } } }

