.form {
  &__title {
    margin-bottom: 35px;
    @include lg {
      margin-bottom: 25px; }
    @include md {
      margin-bottom: 15px; } }
  &__field {
    @include notlast {
      margin-bottom: 20px;
      @include md {
        margin-bottom: 15px; } } } }

.input {
  $this: &;
  text-align: left;
  position: relative;
  input, textarea {
    height: 40px;
    width: 100%;
    padding: .5em 0;
    border-bottom: 1px solid #dcdcdc;
    @include tr(border-color .3s);
    &:focus {
      border-color: $accent; }
    @include placeholder {
      color: $gray-100;
      text-transform: uppercase; } }
  textarea {
    min-height: 75px; }

  &.has-focus, &.has-text {
    #{$this}__label {
      font-size: .75em;
      transform: translate(0, -1.4em); } }
  &.is-disabled {
    pointer-events: none; }

  &__label {
    &:first-child {
      position: absolute;
      text-transform: uppercase;
      color: $gray-100;
      z-index: 1;
      pointer-events: none;
      left: 0;
      top: .6em;
      @include tr(transform .3s); } }

  &--select {
    &.has-text {
      #{$this}__label {
        font-size: .75em;
        transform: translate(0, -1.4em); } } } }

.tel-input {
  text-align: left;
  position: relative;
  @include flex;
  &__select {
    width: 20%;
    padding-right: 15px;
    .custom-select, .input {
      position: static; }
    @include xss {
      width: 25%;
      padding-right: 5px; } }
  &__input {
    width: 80%;
    @include xss {
      width: 75%; } } }

.custom-select {
  $this: &;
  position: relative;
  select {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    z-index: -1; }
  &.is-open {
    #{$this}__opener {
      border-color: $accent; }
    #{$this}__panel {
      @include visible; } }
  &__opener {
    border-bottom: 1px solid #dcdcdc;
    height: 40px;
    padding: .5em 0px;
    text-transform: uppercase;
    @include tr(border-color .3s);
    @include flex(flex-start, center);
    position: relative;
    padding-right: 12px;
    cursor: pointer;
    @include before {
      pointer-events: none;
      @include arr(10 , 5, $accent, b);
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      right: 0;
      z-index: 1; } }
  &__opener-text {
    @include text-overflow; }
  &__panel {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    z-index: 3;
    background-color: $white;
    border: 1px solid #dcdcdc;
    border-top: 0;
    @include hidden;
    @include ready {
      @include tr(visibility .3s, opacity .3s); } }
  &__panel-item {
    padding: .5em .2em; }
  &__options {
    max-height: 260px;
    overflow: auto; }
  &__option {
    padding: .5em .2em;
    @include flex(flex-start, center);
    cursor: pointer;
    @include tr(color .3s, background-color .3s);
    @include hover {
      background-color: $gray-light; }
    &.is-selected {
      background-color: $gray-light; }
    &[data-value="placeholder"] {
      display: none; } }
  &__option-text {
    @include text-overflow;
    span {
      color: $gray-100; } }
  &__flag {
    width: 20px;
    flex: 0 0 20px;
    margin-right: .4em;
    @include image('flags.png'); } }
