.hero {
  $this: &;
  color: $white;
  position: relative;
  overflow: hidden;
  &__inner {
    padding-top: 14%;
    padding-bottom: 11%;
    text-align: center;
    position: relative;
    z-index: 1;
    @include sm {
      // padding-top: 20vh
      // padding-bottom: 20vh
      min-height: 50vh;
      @include flex(center, normal, column); } }
  &__title {
    margin-bottom: 10px;
    @include md {
      margin-bottom: 5px; } }
  &__bg {
    @include cover;
    @include coverdiv;
    z-index: 0;
    background-color: $gray;
    video {
      @include coverdiv;
      width: 100%;
      height: 100%;
      object-fit: cover; }
    &.is-animated {
      animation: scale 10s infinite alternate; } }
  &__word {
    position: absolute;
    z-index: 0;
    right: -5%;
    top: 5%;
    opacity: .1;
    font-weight: 700;
    @include css-lock(100, 240, 320, 1399, font-size); }


  &--item {
    color: inherit;
    background-color: $gray-lightest;
    #{$this}__inner {
      @include flex(flex-start, normal, row, wrap);
      text-align: left;
      padding-top: 20px;
      padding-bottom: 20px; }
    #{$this}__left, #{$this}__right {
      @include flex(center, normal, column);
      width: 50%; }
    #{$this}__img {
      text-align: center; }
    #{$this}__content {
      max-width: 470px;
      margin-left: auto;
      margin-right: auto; } } }



.hero-slide {
  $this: &;
  position: relative;
  color: $white;
  overflow: hidden;
  @include before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 30%;
    z-index: 1;
    background-image: linear-gradient(to top, rgba(0,0,0,0) 0%, rgb(0,0,0) 100%); }
  &__bg {
    @include coverdiv;
    z-index: 0;
    @include cover;
    background-color: $black;
    video {
      @include coverdiv;
      width: 100%;
      height: 100%;
      object-fit: cover; }
    &.is-animated {
      &.is-loaded {
        .swiper-slide-active & {
          animation: scale 5s; } } } }
  &__inner {
    position: relative;
    z-index: 2;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    @include flex(center, normal, column);
    @include xs {
      padding-left: 20px;
      padding-right: 20px; } }
  &__content {
    // max-width: 495px
    max-width: 545px; }
  &__subttl {
    margin-bottom: 15px;
    text-shadow: 3px 3px 10px rgba($black, .5); }
  &__title {
    margin-bottom: 10px;
    text-shadow: 3px 3px 10px rgba($black, .5); }

  &--center {
    #{$this}__content {
      margin-left: auto;
      margin-right: auto;
      text-align: center; } }
  &--right {
    #{$this}__content {
      margin-left: auto; } }

  &--half {
    @include before {
      display: none; }
    #{$this}__content {
      // max-width: 50%
      // margin-left: auto
 }      // margin-right: auto
    #{$this}__inner {
      justify-content: flex-start;
      flex-wrap: wrap;
      flex-direction: row;
      padding-left: 0;
      padding-right: 0;
      @include sm {
        height: auto; } }
    #{$this}__left, #{$this}__right {
      width: 50%;
      background-color: $black;
      @include sm {
        width: 100%; } }
    #{$this}__left {
      @include flex(center, normal, column);
      padding-right: 15px;
      padding-left: 80px + 20px;
      @include xl {
        padding-left: 50px + 20px; }
      @include lg {
        padding-left: 15px + 20px; }
      @include sm {
        order: 1;
        padding-top: 15px;
        padding-left: 15px;
        padding-bottom: 15px;
        height: auto; } }
    #{$this}__right {
      @include cover;
      @include sm {
        padding-top: 90%;
        order: 0; } }
    #{$this}__title {
      margin-bottom: 30px;
      @include md {
        margin-bottom: 15px; } }
    #{$this}__subttl {
      margin-bottom: 30px;
      @include md {
        margin-bottom: 15px; } } } }



@keyframes scale {
  0% {
    transform: scale(1.1); }
  100% {
    transform: scale(1); } }

