.item-card {
  &__inner {
    @include flex(flex-start, normal, row, wrap);
    padding-top: 30px;
    padding-bottom: 30px;
    @include md {
      padding-top: 15px;
      padding-bottom: 15px;
      display: block; } }
  &__gallery {
    width: 50%;
    @include md {
      width: auto;
      margin-bottom: 30px; }
    @include sm {
      margin-bottom: 15px; } }
  &__content {
    width: 50%;
    padding-left: 52px;
    padding-right: percentage(137/1340);
    position: relative;
    @include flex(flex-start, normal, column);
    @include xl {
      padding-left: 35px;
      padding-right: 35px; }
    @include lg {
      padding-left: 15px;
      padding-right: 15px; }
    @include md {
      width: auto;
      padding-left: 0;
      padding-right: 0; } }
  &__meta {
    width: percentage(1084/1340);
    margin-left: percentage((1340 - 1084) / 1340);
    @include md {
      margin-left: 0;
      width: 100%; } }
  &__title, &__text {
    margin-bottom: 15px; }
  &__title {
    @include flex(flex-start, center);
    .title {
      margin-right: .5em; } }
  &__list {
    margin-bottom: 35px;
    flex-grow: 1;
    @include md {
      margin-bottom: 20px; }
    @include sm {
      margin-bottom: 10px; } } }

.items-list {
  li {
    @include flex(space-between, center);
    font-weight: 300;
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #e8e8e8;
    &:first-child {
      border-top: 1px solid #e8e8e8; }
    strong {
      font-weight: 600; } } }

.item-card-meta-blocks {
  @include flex(flex-start, normal, row, wrap);
  margin-left: -2px;
  margin-right: -2px;
  @include md {
    justify-content: center; }
  &__item {
    padding-left: 2px;
    padding-right: 2px;
    width: 180px;
    margin-bottom: 4px;
    @include md {
      width: 20%; }
    @include sm {
      width: 33.33333%; }
    @include xs {
      width: 50%; } } }

.item-card-meta-block {
  height: 100%;
  width: 100%;
  background-color: $gray-lightest;
  text-align: center;
  @include flex(flex-start, normal, column);
  position: relative;
  padding: 18px 10px;
  border: 2px solid transparent;
  @include tr(border-color .3s);
  @include hover {
    border-color: $accent; }
  &.is-active {
    border-color: $accent; }
  &__img {
    width: 80px;
    height: 80px;
    margin-left: auto;
    margin-right: auto;
    // background-color: #e6e6e6
    @include flex(center, center);
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 20px;
    position: relative;
    color: #414141;
    img {
      &.is-loaded {
        @include coverdiv; } }
    .icon {
      font-size: em(40);
      margin-top: -.1em; } }
  &__title {
    flex-grow: 1;
    text-transform: uppercase;
    font-weight: 300;
    @include flex(center, normal, column);
    text-align: center; }
  &__icon {
    position: absolute;
    z-index: 1;
    display: block;
    background-color: $white;
    width: 15px;
    height: 15px;
    top: 5px;
    right: 5px;
    &::before, &::after {
      content: '';
      @include vcenter;
      background-color: currentColor; }
    &::before {
      width: 10px;
      height: 1px; }
    &::after {
      width: 1px;
      height: 10px; } } }

