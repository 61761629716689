.features-sm {
  @include flex(flex-start, normal, row, wrap);
  .feature-sm {
    width: 25%;
    @include md {
      width: 50%; } } }

.feature-sm {
  text-align: center;
  background-color: $gray-lightest;
  padding: 10% 15px;
  @include notlast {
    border-right: 1px solid #eeeeee; }
  @include md {
    border-bottom: 1px solid  #eee; }
  &__icon {
    color: $accent;
    margin-bottom: 25px;
    @include md {
      margin-bottom: 15px; }
    .icon {
      font-size: em(65); } } }

.features {
  @include flex(center, normal, row, wrap);
  margin-left: -25px;
  margin-right: -25px;
  @include xl {
    margin-left: -15px;
    margin-right: -15px; }
  &__item {
    padding-left: 25px;
    padding-right: 25px;
    width: 33.33333%;
    @include xl {
      padding-left: 15px;
      padding-right: 15px; }
    @include md {
      width: 50%; }
    @include sm {
      width: 100%; } } }

.feature {
  $this: &;
  @include flex(flex-start, center);
  margin-bottom: 60px;
  @include xl {
    margin-bottom: 50px; }
  @include lg {
    margin-bottom: 40px; }
  @include md {
    margin-bottom: 25px; }
  @include sm {
    margin-bottom: 15px; }
  &__icon {
    flex: 0 0 82px;
    width: 82px;
    height: 82px;
    border-radius: 50%;
    background-color: $gray-lightest;
    @include flex(center, center);
    color: $accent;
    margin-right: 20px;
    @include tr(color .3s, background-color .3s);
    @include md {
      margin-right: 10px;
      flex: 0 0 70px;
      width: 70px;
      height: 70px; }
    .icon {
      font-size: 35px; } }
  &--text {
    @include hover {
      #{$this}__icon {
        background-color: black;
        color: $white; } }
    #{$this}__icon {
      color: inherit; } } }
