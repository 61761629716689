.social {
  li {
    display: inline-block;
    background-color: #2d2d2d;
    border-radius: 50%;
    margin-left: 5px;
    margin-right: 5px;
    @include tr(background-color .3s);
    @include hover {
      background-color: $accent; }
    @include md {
      margin-left: 2px;
      margin-right: 2px; } }
  a {
    @include flex(center, center);
    color: $white;
    width: 40px;
    height: 40px;
    .icon {
      font-size: em(22); }
    @include md {
      width: 30px;
      height: 30px;
      .icon {
        font-size: em(16); } } } }
