.header {
  $this: &;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: transparent;
  color: $white;
  @include tr(background-color .3s);
  &.is-white {
    background-color: $white;
    box-shadow: 0px 0px 12px 0px rgba(1, 0, 0, 0.17);
    color: inherit; }
  &.is-fixed {
    position: fixed;
    #{$this}__inner {
      height: $header-height;
      @include md {
 } } }        // height: $header-height-md
  &__inner {
    height: $header-transp-height;
    @include flex(space-between, center);
    padding-top: 10px;
    padding-bottom: 10px;
    @include lg {
      height: $header-transp-height-lg; } }
  &__nav {
    text-align: right;
    @include md {
      position: fixed;
      z-index: 10;
      padding: 15px;
      overflow: auto;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background-color: rgba($gray-light, .9);
      @include vertical;
      color: $c-text;
      @include ready {
        @include tr(visibility .3s, opacity .3s); }
      @include hidden;
      &.is-active {
        @include visible; } } }
  &__nav-in {
    @include md {
      display: inline-block;
      vertical-align: middle;
      width: 100%;
      font-size: $fz; }
    @include sm {
      font-size: $fz-sm-px; } }
  &__burger {
    margin-left: 15px;
    display: none;
    z-index: 11;
    @include md {
      display: block; } }
  &__logo {
    z-index: 11; }
  &__close {
    display: none;
    @include md {
      display: block; } } }

.burger {
  display: block;
  width: 28px;
  height: 28px;
  position: relative;
  span:after, span:before {
    content: "";
    position: absolute;
    left: 0; }
  span:after {
    top: 5px; }
  span:before {
    bottom: 5px; }
  span {
    display: block; }
  span, span:after, span:before {
    width: 100%;
    height: 2px;
    background-color: currentColor;
    backface-visibility: hidden;
    border-radius: 2px;
    @include tr(transform .3s); }
  &.is-active span {
    background-color: transparent; }
  &.is-active span:before {
    transform: rotate(45deg) translate(-7px,-5px); }
  &.is-active span:after {
    transform: rotate(-45deg) translate(-6px,4px); } }

